import React, { useEffect, useMemo, useCallback, useState, useContext } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

// Components
import EventLeaderList from "../../components/Shared/EventLeaderList"
import HeroAreaSmall from "../../components/Shared/HeroAreaSmall"
import Main from "../../components/Shared/Main"
import WhatsOnFilters from "../../components/WhatsOn/WhatsOnFilters"
import EventLeader from "../../components/Shared/EventLeader"
import LoadMore from "../Shared/LoadMore"
import Loading from "../Shared/Loading"

import { WhatsOnContext } from "../../context/WhatsOnContext"

import * as styles from "./WhatsOnContainer.module.scss"

export const query = gql`
    query EventQuery($first: Int!, $after: String, $notIn: [ID!], $venue: Int, $audience_type: Int, $event_type: Int, $event_theme: Int, $date: String) {
        events(
            first: $first
            after: $after
            where: { upcoming: true, venue: $venue, audienceType: $audience_type, eventType: $event_type, eventTheme: $event_theme, date: $date, notIn: $notIn }
        ) {
            results: nodes {
                id
                databaseId
                title
                publishToLive {
                    publishToLive
                }
                link
                offsite_event_warning {
                    display: displayEventLocationWarning
                    description: warningMessage
                    title: warningTitle
                    hideVenueName
                }
                event_fields {
                    eventDates {
                        startDate
                        endDate
                    }
                }
                contentType {
                    node {
                        name
                    }
                }
                image: hero_fields {
                    image {
                        sourceUrl
                        title
                    }
                }
                venueFields {
                    venue {
                        ... on Venue {
                            id
                            title
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`

const PER_PAGE = 8
const PAGINATION_OFFSET = 1 // initial page load gets 8 but we then render addition rows of 9

// Window Object
let store = typeof window === "undefined" ? {} : window
store.eventFilters = {}

export const eventSort = (a, b) => {
    const instances_a = a.event_fields.eventDates.map((d) => new Date(d.startDate)).sort()
    const instances_b = b.event_fields.eventDates.map((d) => new Date(d.startDate)).sort()
    return instances_a[0] < instances_b[0] ? -1 : 1
}

const WhatsOnContainer = (props) => {
    // Whats On Context
    const { allFilters, setAllFilters } = useContext(WhatsOnContext)

    const [params, setParams] = useState({ first: PER_PAGE, after: null, venue: props.venueId })

    let { data, loading, fetchMore } = useQuery(query, {
        variables: params,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    })

    const updateFilteredResults = useCallback(
        (filters) => {
            const updatedParams = { ...params, ...filters }
            setParams(updatedParams)
        },
        [params]
    )

    const allEvents = useMemo(() => {
        let events

        if (data) {
            events = data?.events ? data?.events?.results : []
        } else {
            events = props.data.allEvents.results
        }

        const filteredEvents = events.filter((item) => {
            // console.log("item", item)

            // LIVE - PREVIEW
            if (process.env.GATSBY_IS_LIVE_OR_PREVIEW !== "live") {
                return true
            } else {
                // If the event has a publishToLive field, check if it is set to "Live"
                // If it is not set, return true as this should be the default
                if (item?.publishToLive?.publishToLive) {
                    return item?.publishToLive?.publishToLive === "Live"
                } else {
                    return true
                }
            }
        })

        return [...filteredEvents]?.sort(eventSort)
    })

    return (
        <>
            <HeroAreaSmall title="What’s on" image={props.data.page.image.image} />

            <Main>
                <WhatsOnFilters onChange={updateFilteredResults} filters={props.filters} hideVenueFilter={props.venueId ? true : false} />

                {allEvents.length === 0 && <p>No events found</p>}

                {!loading ? (
                    <>
                        <EventLeaderList layoutType="twoColumn">
                            {allEvents.slice(0, 2).map((event) => (
                                <EventLeader key={event.id} {...event} />
                            ))}
                        </EventLeaderList>

                        <EventLeaderList layoutType="threeColumn">
                            {allEvents.slice(2).map((event) => (
                                <EventLeader key={event.id} {...event} />
                            ))}
                        </EventLeaderList>
                    </>
                ) : (
                    <div>
                        <Loading loading={true} />
                    </div>
                )}

                {data && (
                    <LoadMore
                        onClick={() => {
                            fetchMore({
                                variables: {
                                    first: params.first + PAGINATION_OFFSET,
                                    after: null,
                                    notIn: allEvents.map((e) => e.databaseId),
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                        return prev
                                    }

                                    let results = [...prev.events.results, ...fetchMoreResult.events.results]
                                    return {
                                        ...prev,
                                        ...{ events: { results, pageInfo: fetchMoreResult.events.pageInfo } },
                                    }
                                },
                            })
                        }}
                        disabled={data && data.events && !data.events.pageInfo.hasNextPage}
                        loading={loading}
                    />
                )}
            </Main>
        </>
    )
}

export default WhatsOnContainer
