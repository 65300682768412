import React from "react"
import { useQuery } from "@apollo/client"

import EventLeaderList from "../Shared/EventLeaderList"
import EventLeader from "../Shared/EventLeader"
import * as styles from "../Shared/LoadMore.module.scss"

import {query as latestEventsQuery, eventSort} from "../WhatsOn/WhatsOnContainer"

const LatestEvents  = ({eventData, venueId}) => {
    const params = {
        first: eventData.highlightedEventsCount,
        after: null, 
        venue: venueId
    }

    let { data, loading } = useQuery(latestEventsQuery, { variables: params, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true })

    const sortedEvents = (events) => [...events].sort(eventSort)

    return <div>
        {loading && (
            <div className={styles.LoadMore}>
                <div className={styles.spinner} style={{top: '-50px'}}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )}
        
        <EventLeaderList>
            {!loading && data && data.events && (
                sortedEvents(data.events.results).map((event) => <EventLeader key={event.id} {...event} />)
            )}
        </EventLeaderList>
    </div>
}

export default LatestEvents