import React from "react"

// Styles
import * as styles from "./HomeHero.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import ParallaxItem from "../Shared/ParallaxItem"
import Image from "../Shared/Image"
import { imageSizes, placeholderImage } from "../../helpers/imgix"

const HomeHero = (props) => {
    return (
        <div className={styles.HomeHero}>
            <div className={styles.image}>
                <ParallaxItem>{props.image && <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />}</ParallaxItem>
            </div>
            <div className={`${styles.textContent} ${props.link ? "" : styles.textContentNoLink}`}>
                <a href={props.link ? props.link.url : undefined}>
                    <h1>{props.title} </h1>
                    <h2 dangerouslySetInnerHTML={{ __html: props.subtitle }}></h2>
                    {props.venue && <span className={styles.venue} dangerouslySetInnerHTML={{ __html: props.venue.title }}></span>}
                    {props.dates && <span className={styles.date}>{props.dates}</span>}
                </a>
            </div>
        </div>
    )
}

export default HomeHero
