import React from "react"

import * as styles from "./HomeWhatsOn.module.scss"

const HomeWhatsOn = ({children}) => (

    <div className={styles.HomeWhatsOn}>
        {children}
    </div>

)

export default HomeWhatsOn