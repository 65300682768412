import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getHomepagePreview as PREVIEW_QUERY } from "../preview-queries/homepagePreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import HomeHero from "../components/Home/HomeHero"
import HomeAlert from "../components/Home/HomeAlert"
import EventLeaderList from "../components/Shared/EventLeaderList"
import LargeImageCTA from "../components/Shared/LargeImageCTA"
import LargeMixedCTA from "../components/Shared/LargeMixedCTA"
import HomeWhatsOn from "../components/Home/HomeWhatsOn"
import LatestEvents from "../components/Home/LatestEvents"
import EventLeader from "../components/Shared/EventLeader"
import Main from "../components/Shared/Main"
import { venueForPage } from "../helpers/venueForPage"
import { ResponsiveContext } from "grommet"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateHome = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({
        page: page,
        menus: props.data.menus,
    })

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <meta name="cf-2fa-verify" content="w8tIZahj8ALIcN3ZH3FS" />
            {/* <SEOSettings entity={page} /> */}
            {props.preview && !(props.preview.page.revisions.edges.length > 0) && <div>Preview not available</div>}

            <ResponsiveContext.Consumer>
                {(responsive) =>
                    (responsive === "tablet" || responsive === "desktop" || responsive === "wide") && (
                        <>{page.homepage_fields?.alert.displayAlert && <HomeAlert {...page.homepage_fields.alert} />}</>
                    )
                }
            </ResponsiveContext.Consumer>

            <HomeHero {...page.homepage_fields.hero} />

            <Main>
                <ResponsiveContext.Consumer>
                    {(responsive) =>
                        (responsive === "smallMobile" || responsive === "mobile") && (
                            <>{page.homepage_fields.alert.displayAlert && <HomeAlert {...page.homepage_fields.alert} />}</>
                        )
                    }
                </ResponsiveContext.Consumer>

                {page.homepage_fields?.highlightedEventsConfig !== "none" && (
                    <HomeWhatsOn>
                        <h2>What's on</h2>
                        {page.homepage_fields?.highlightedEventsConfig === "latest" && (
                            <LatestEvents eventData={page.homepage_fields} venueId={venue.venueId} />
                        )}

                        {page.homepage_fields?.highlightedEventsConfig === "curated" && (
                            <CuratedEvents eventData={page.homepage_fields} showLinkToAllEvents={true} />
                        )}

                        <a href="/whats-on">All events</a>
                    </HomeWhatsOn>
                )}

                {page.homepage_fields?.collectionHighlight.title && <LargeImageCTA {...page.homepage_fields.collectionHighlight} />}
                {page.homepage_fields?.supportUsHighlight.title && <LargeMixedCTA {...page.homepage_fields.supportUsHighlight} />}
                {page.homepage_fields?.visitUsHighlight.title && <LargeImageCTA {...page.homepage_fields.visitUsHighlight} />}
                {page.homepage_fields?.facilitiesHighlight.title && <LargeImageCTA {...page.homepage_fields.facilitiesHighlight} />}
            </Main>
        </Layout>
    )
}

const CuratedEvents = ({ eventData }) => {
    return (
        <div>
            <EventLeaderList>
                {(eventData.whatsOn || [])
                    .filter((event) => event)
                    .map((leader, index) => (
                        <EventLeader key={`event-leader-${index}`} {...leader} />
                    ))}
            </EventLeaderList>
        </div>
    )
}

export const pageQuery = graphql`
    query HomePageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            title
            homepage_fields {
                hero {
                    dates
                    link {
                        target
                        title
                        url
                    }
                    venue {
                        ... on WpVenue {
                            id
                            title
                        }
                    }
                    title
                    subtitle
                    image {
                        sourceUrl
                        title
                    }
                }
                collectionHighlight {
                    supertitle
                    buttonText
                    description
                    title
                    link {
                        target
                        title
                        url
                    }
                    image {
                        sourceUrl
                        title
                    }
                }
                supportUsHighlight {
                    supertitle
                    buttonText
                    description
                    image {
                        sourceUrl
                        title
                    }
                    link {
                        target
                        title
                        url
                    }
                    title
                }
                visitUsHighlight {
                    supertitle
                    buttonText
                    description
                    title
                    image {
                        sourceUrl
                        title
                    }
                    link {
                        target
                        title
                        url
                    }
                }
                facilitiesHighlight {
                    supertitle
                    buttonText
                    description
                    title
                    image {
                        sourceUrl
                        title
                    }
                    link {
                        target
                        title
                        url
                    }
                }
                highlightedEventsConfig
                highlightedEventsCount
                whatsOn {
                    ... on WpEvent {
                        id
                        title
                        link
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        offsite_event_warning {
                            display: displayEventLocationWarning
                            description: warningMessage
                            title: warningTitle
                            hideVenueName
                        }
                        event_fields {
                            eventDates {
                                startDate
                                endDate
                            }
                        }
                        venueFields {
                            venue {
                                ... on WpVenue {
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
                alert {
                    displayAlert
                    title
                    description
                    link {
                        target
                        title
                        url
                    }
                }
            }
            venueFields {
                venue {
                    ... on WpVenue {
                        id
                        slug
                        venue_fields {
                            venueNavigationMenuId
                            hasCustomNavigationMenu
                        }
                    }
                }
            }
        }
        ctas: allWpCallstoAction {
            nodes {
                title
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateHome)
