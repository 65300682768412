import React, { useState, useCallback, useMemo, useEffect, useContext } from "react"
import { Select } from "grommet"

import DateInput from "../Shared/DateInput"
// import { DateInput } from 'grommet-controls' // DateInput in Grommet Controls doesn't work with non-US date formats

import * as styles from "./WhatsOnFilters.module.scss"
import { decode } from "../../helpers/stringHelpers"
import { useQueryString } from "../../utils/hooks/useQueryString"

import { WhatsOnContext } from "../../context/WhatsOnContext"

const WhatsOnFilters = (props) => {
    const [refresh, setRefresh] = useState(false)
    const [audienceValue, setAudienceValue] = useState({})
    const [typeValue, setTypeValue] = useState({})
    const [themeValue, setThemeValue] = useState({})
    const [venueValue, setVenueValue] = useState({})

    // Whats On Context
    const { allFilters, setAllFilters, whatsOnDate, setWhatsOnDate } = useContext(WhatsOnContext)

    // if (typeof window === "undefined") {
    //     var window = { eventFilters: {} }
    // }

    const { value: queryStringValues, updateQueryString, initialQueryRun } = useQueryString()
    // console.log("What's on filters: ",JSON.stringify(props.filters))
    const filters = useMemo(() => {
        return Object.keys(props.filters)
            .filter((filter) => {
                return !(props.hideVenueFilter && filter === "venue")
            })
            .map((filterType) => {
                
                const filterName = (filterType) => {
                    const type = filterType.replace(/^WPGraphQL_/, "")

                    switch (type) {
                        case "WpAudience":
                            return "Audience"
                        case "WpEventType":
                            return "Event Type"
                        case "WpVenue":
                            return "Venue"
                        case "WpEventTheme":
                            return "Event Theme"
                        default:
                            return type
                    }
                }
                let filterTitle
                if(props.filters[filterType].options[0].filter){
                    filterTitle = filterName(props.filters[filterType].options[0].filter.__typename)
                }
                // console.log("raw: ", props.filters[filterType].options[0].filter.__typename)
                // console.log("processed: ",filterTitle)
                const filterOptions = props.filters[filterType].options.map((option) => ({
                    id: option.filter.id,
                    title: decode(option.filter.title),
                    slug: option.filter.slug,
                }))

                // if (window.eventFilters[filterType]) {
                //     filterOptions.unshift({ id: null, title: `All` })
                // }

                return {
                    id: filterType,
                    name: filterTitle,
                    options: filterOptions,
                }
            })
    })

    // Get all filters options, names, ids
    const audienceObject = useMemo(() => {
        return filters.find((obj) => {
            return obj.id === "audience_type"
        })
    }, [])

    const eventTypeObject = useMemo(() => {
        return filters.find((obj) => {
            return obj.id === "event_type"
        })
    }, [])

    const venueObject = useMemo(() => {
        return filters.find((obj) => {
            return obj.id === "venue"
        })
    }, [])

    const eventThemeObject = useMemo(() => {
        return filters.find((obj) => {
            return obj.id === "event_theme"
        })
    }, [])

    const changeFilterIdsToSlugs = (filters) => {
        let obj = {}

        // Add date as normal
        obj = { ...obj, date: filters.date }

        const audienceID = filters.audience_type
        if (audienceID) {
            const audienceItem = audienceObject.options.find((obj) => {
                return parseInt(obj.id) === parseInt(audienceID)
            })
            const audienceSlug = audienceItem.slug

            obj = { ...obj, audience_type: audienceSlug }
        }

        const eventTypeID = filters.event_type
        if (eventTypeID) {
            const eventTypeItem = eventTypeObject.options.find((obj) => {
                return parseInt(obj.id) === parseInt(eventTypeID)
            })
            const eventTypeSlug = eventTypeItem.slug

            obj = { ...obj, event_type: eventTypeSlug }
        }

        const venueID = filters.venue
        if (venueID) {
            const venueItem = venueObject.options.find((obj) => {
                return parseInt(obj.id) === parseInt(venueID)
            })
            const venueSlug = venueItem.slug

            obj = { ...obj, venue: venueSlug }
        }

        const themeID = filters.event_theme
        if (themeID) {
            const eventThemeItem = eventThemeObject.options.find((obj) => {
                return parseInt(obj.id) === parseInt(themeID)
            })
            const themeSlug = eventThemeItem.slug

            obj = { ...obj, event_theme: themeSlug }
        }

        return obj
    }

    const changeFilterSlugsToIds = (slugFilters) => {
        let obj = {}

        // Add date as normal
        obj = { ...obj, date: slugFilters.date }

        const audienceSlug = slugFilters.audience_type
        if (audienceSlug) {
            const audienceItem = audienceObject.options.find((obj) => {
                return obj.slug === audienceSlug
            })
            const audienceId = audienceItem.id

            obj = { ...obj, audience_type: audienceId }
        }

        const eventTypeSlug = slugFilters.event_type
        if (eventTypeSlug) {
            const eventTypeItem = eventTypeObject.options.find((obj) => {
                return obj.slug === eventTypeSlug
            })
            const eventTypeId = eventTypeItem.id

            obj = { ...obj, event_type: eventTypeId }
        }

        const venueSlug = slugFilters.venue
        if (venueSlug) {
            const venueItem = venueObject.options.find((obj) => {
                return obj.slug === venueSlug
            })
            const venueId = venueItem.id

            obj = { ...obj, venue: venueId }
        }

        const themeSlug = slugFilters.event_theme
        if (themeSlug) {
            const themeItem = eventThemeObject.options.find((obj) => {
                return obj.slug === themeSlug
            })
            const themeId = themeItem.id

            obj = { ...obj, event_theme: themeId }
        }

        return obj
    }

    // Set Query Filter Value and Update Select component values
    const setFilterValue = useCallback(
        (id, valueID) => {
            switch (id) {
                case "audience_type":
                    const audienceFilterOption = audienceObject.options.find((obj) => {
                        return obj.id === parseInt(valueID)
                    })
                    setAudienceValue({ title: audienceFilterOption.title, id: audienceFilterOption.id })
                    break

                case "event_type":
                    const typeFilterOption = eventTypeObject.options.find((obj) => {
                        return obj.id === parseInt(valueID)
                    })
                    setTypeValue({ title: typeFilterOption.title, id: typeFilterOption.id })
                    break

                case "event_theme":
                    const themeFilterOption = eventThemeObject.options.find((obj) => {
                        return obj.id === parseInt(valueID)
                    })
                    setThemeValue({ title: themeFilterOption.title, id: themeFilterOption.id })
                    break

                case "venue":
                    const venueFilterOption = venueObject.options.find((obj) => {
                        return obj.id === parseInt(valueID)
                    })
                    setVenueValue({ title: venueFilterOption.title, id: venueFilterOption.id })
                    break

                case "date":
                    setWhatsOnDate(valueID)
                    break

                default:
                    break
            }
            let filter = {}
            filter[id] = valueID
            // let filters = { ...window.eventFilters, ...filter }

            const savedFilters = allFilters
            let filters = { ...savedFilters, ...filter }

            const queryFilters = changeFilterIdsToSlugs(filters)

            updateQueryString({ ...queryFilters })
            //window.eventFilters = filters
            setAllFilters({ ...filters })
            props.onChange(filters)
        },
        [allFilters]
    )

    const resetFilters = useCallback(() => {
        // window.eventFilters = {}
        setAllFilters({})

        // Remove filter values
        setAudienceValue({})
        setTypeValue({})
        setThemeValue({})
        setVenueValue({})
        setWhatsOnDate("")

        // Update Query String
        let filters = {}
        updateQueryString({ ...filters })

        props.onChange(filters)

        setRefresh(!refresh)
    }, [])

    // Get Values from query String on page load
    // Update Select Filter Values
    // Set Filters
    useEffect(() => {
        if (!queryStringValues) {
            return
        }

        const { date, audience_type, event_type, event_theme, venue } = changeFilterSlugsToIds(queryStringValues)

        let filters

        if (date) {
            filters = { ...filters, date }
            setWhatsOnDate(date)
        }

        if (audience_type) {
            filters = { ...filters, audience_type }

            const filterOption = audienceObject.options.find((obj) => {
                return obj.id === parseInt(audience_type)
            })
            setAudienceValue({ title: filterOption.title, id: filterOption.id })
        }

        if (event_type) {
            filters = { ...filters, event_type }

            const filterOption = eventTypeObject.options.find((obj) => {
                return obj.id === parseInt(event_type)
            })
            setTypeValue({ title: filterOption.title, id: filterOption.id })
        }

        if (event_theme) {
            filters = { ...filters, event_theme }

            const filterOption = eventThemeObject.options.find((obj) => {
                return obj.id === parseInt(event_theme)
            })
            setThemeValue({ title: filterOption.title, id: filterOption.id })
        }

        if (venue) {
            filters = { ...filters, venue }

            const filterOption = venueObject.options.find((obj) => {
                return obj.id === parseInt(venue)
            })
            setVenueValue({ title: filterOption.title, id: filterOption.id })
        }

        setAllFilters({ ...filters })

        props.onChange(filters)
    }, [queryStringValues])

    return (
        <div>
            <div className={styles.WhatsOnResetFilters}>
                <button onClick={() => resetFilters()}>Reset Filters</button>
            </div>
            <div className={styles.WhatsOnFilters} key={`refresh-${refresh}`}>
                <DateInput date-filter-id={`date`} size="small" onChange={(date) => setFilterValue("date", date)} />

                {/* Audience Filter */}
                {audienceObject?.options && (
                    <Select
                        key={`filter-${audienceObject?.id}`}
                        data-filter-id={audienceObject?.id}
                        options={audienceObject?.options}
                        labelKey={`title`}
                        valueKey={`id`}
                        placeholder={audienceObject?.name}
                        value={audienceValue ? audienceValue : ""}
                        onChange={({
                            target: {
                                dataset: { filterId },
                            },
                            value,
                        }) => setFilterValue(filterId, value.id)}
                    />
                )}

                {/* Event Type Filter */}
                {eventTypeObject?.options && (
                    <Select
                        key={`filter-${eventTypeObject?.id}`}
                        data-filter-id={eventTypeObject?.id}
                        options={eventTypeObject?.options}
                        labelKey={`title`}
                        valueKey={`id`}
                        placeholder={eventTypeObject?.name}
                        value={typeValue ? typeValue : ""}
                        onChange={({
                            target: {
                                dataset: { filterId },
                            },
                            value,
                        }) => setFilterValue(filterId, value.id, value)}
                    />
                )}

                {/* Venue Filter */}
                {venueObject?.options && (
                    <Select
                        key={`filter-${venueObject?.id}`}
                        data-filter-id={venueObject?.id}
                        options={venueObject?.options}
                        labelKey={`title`}
                        valueKey={`id`}
                        placeholder={venueObject?.name}
                        value={venueValue ? venueValue : ""}
                        onChange={({
                            target: {
                                dataset: { filterId },
                            },
                            value,
                        }) => setFilterValue(filterId, value.id, value)}
                    />
                )}

                {/* Event Theme Filter */}
                {eventThemeObject?.options && (
                    <Select
                        key={`filter-${eventThemeObject?.id}`}
                        data-filter-id={eventThemeObject?.id}
                        options={eventThemeObject?.options}
                        labelKey={`title`}
                        valueKey={`id`}
                        placeholder={eventThemeObject?.name}
                        value={themeValue ? themeValue : ""}
                        onChange={({
                            target: {
                                dataset: { filterId },
                            },
                            value,
                        }) => setFilterValue(filterId, value.id, value)}
                    />
                )}
            </div>
        </div>
    )
}

export default WhatsOnFilters
