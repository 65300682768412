import React from "react"

// Styles
import * as styles from "./HomeAlert.module.scss"

const HomeAlert = ({ title, description, link }) => {
    const alertContent = (
        <div className={styles.textContent}>
            <h1>{title}</h1>
            <h2>{description}</h2>
        </div>
    )

    if (link) {
        return (
            <div className={styles.HomeAlert}>
                <a href={link.url}>{alertContent}</a>
            </div>
        )
    } else {
        return <div className={styles.HomeAlert}>{alertContent}</div>
    }
}

export default HomeAlert
