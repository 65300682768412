import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./LargeImageCTA.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const textContentVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeOut" } },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const LargeImageCTA = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <div className={styles.LargeImageCTA}>
            <h2 className={styles.sectionTitle}>{props.supertitle}</h2>
            <div className={styles.image}>{props.image && <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />}</div>
            <motion.div
                className={styles.textContent}
                ref={ref}
                animate={controls}
                initial={typeof window !== "object" ? "visible" : "hidden"}
                variants={textContentVariants}
            >
                {props.title && <h3>{props.title}</h3>}
                {props.description && <div>{props.description}</div>}
                {props.link && <a href={props.link.url}>{props.buttonText}</a>}
            </motion.div>
        </div>
    )
}

export default LargeImageCTA
