import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./LargeMixedCTA.module.scss"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"

const BoxVariants = {
    visible: {
        opacity: 1,
        x: 0,
        transition: { duration: 0.8, ease: "easeOut", when: "beforeChildren" },
    },
    hidden: { opacity: 0, x: -80 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    BoxVariants.hidden.opacity = 1
    BoxVariants.hidden.x = 0
}

const LargeMixedCTA = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <div className={styles.LargeMixedCTA}>
            <h2>{props.supertitle}</h2>
            <motion.div
                className={styles.image}
                ref={ref}
                animate={controls}
                initial={typeof window !== "object" ? "visible" : "hidden"}
                variants={BoxVariants}
            >
                {props.image && <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.default} />}
            </motion.div>
            <div className={styles.textContent}>
                {props.title && <h2>{props.title}</h2>}
                {props.description && <div className={styles.description}>{props.description}</div>}
                {props.link && <a href={props.link.url}>{props.buttonText}</a>}
            </div>
        </div>
    )
}

export default LargeMixedCTA
