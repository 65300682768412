import gql from "graphql-tag"

export const getHomepagePreview = gql`
    query getHomepagePreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        content
                        contentType {
                            node {
                                name
                            }
                        }
                        venue {
                            venue {
                                ... on Venue {
                                    venueId: databaseId
                                    slug
                                    venue_fields {
                                        hasCustomNavigationMenu
                                        venueNavigationMenuId
                                    }
                                }
                            }
                        }
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        sub_title {
                            subTitle
                        }
                        homepage_fields {
                            hero {
                                dates
                                link {
                                    target
                                    title
                                    url
                                }
                                venue {
                                    ... on Venue {
                                        id
                                        title
                                    }
                                }
                                title
                                subtitle
                                image {
                                    sourceUrl
                                    title
                                }
                            }
                            collectionHighlight {
                                supertitle
                                buttonText
                                description
                                title
                                link {
                                    target
                                    title
                                    url
                                }
                                image {
                                    sourceUrl
                                    title
                                }
                            }
                            supportUsHighlight {
                                supertitle
                                buttonText
                                description
                                image {
                                    sourceUrl
                                    title
                                }
                                link {
                                    target
                                    title
                                    url
                                }
                                title
                            }
                            visitUsHighlight {
                                supertitle
                                buttonText
                                description
                                title
                                image {
                                    sourceUrl
                                    title
                                }
                                link {
                                    target
                                    title
                                    url
                                }
                            }
                            facilitiesHighlight {
                                supertitle
                                buttonText
                                description
                                title
                                image {
                                    sourceUrl
                                    title
                                }
                                link {
                                    target
                                    title
                                    url
                                }
                            }
                            highlightedEventsConfig
                            highlightedEventsCount
                            whatsOn {
                                ... on Event {
                                    id
                                    title
                                    link
                                    image: hero_fields {
                                        image {
                                            sourceUrl
                                            altText
                                            title
                                        }
                                    }
                                    offsite_event_warning {
                                        display: displayEventLocationWarning
                                        description: warningMessage
                                        title: warningTitle
                                        hideVenueName
                                    }
                                    event_fields {
                                        eventDates {
                                            startDate
                                            endDate
                                        }
                                    }
                                    venue {
                                        venue {
                                            ... on Venue {
                                                id
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                            alert {
                                displayAlert
                                title
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`
